import type { AppProps } from "next/app";
import { Analytics } from "@vercel/analytics/react";
import { WalletWrapper } from "../modules/common/WalletWrapper";
import { QueryClient, QueryClientProvider } from "react-query";

import "../styles/globals.css";
import "@rainbow-me/rainbowkit/styles.css";

const queryClient = new QueryClient();

export default function App({ Component, pageProps }: AppProps) {
  return (
    <WalletWrapper>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
        <Analytics />
      </QueryClientProvider>
    </WalletWrapper>
  );
}
